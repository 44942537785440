exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalogs-[id]-index-js": () => import("./../../../src/pages/catalogs/[id]/index.js" /* webpackChunkName: "component---src-pages-catalogs-[id]-index-js" */),
  "component---src-pages-catalogs-[id]-option-lists-index-js": () => import("./../../../src/pages/catalogs/[id]/option-lists/index.js" /* webpackChunkName: "component---src-pages-catalogs-[id]-option-lists-index-js" */),
  "component---src-pages-catalogs-[id]-products-index-js": () => import("./../../../src/pages/catalogs/[id]/products/index.js" /* webpackChunkName: "component---src-pages-catalogs-[id]-products-index-js" */),
  "component---src-pages-catalogs-index-js": () => import("./../../../src/pages/catalogs/index.js" /* webpackChunkName: "component---src-pages-catalogs-index-js" */),
  "component---src-pages-customers-index-js": () => import("./../../../src/pages/customers/index.js" /* webpackChunkName: "component---src-pages-customers-index-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-orders-[id]-index-js": () => import("./../../../src/pages/orders/[id]/index.js" /* webpackChunkName: "component---src-pages-orders-[id]-index-js" */),
  "component---src-pages-orders-index-js": () => import("./../../../src/pages/orders/index.js" /* webpackChunkName: "component---src-pages-orders-index-js" */),
  "component---src-pages-partners-[id]-index-js": () => import("./../../../src/pages/partners/[id]/index.js" /* webpackChunkName: "component---src-pages-partners-[id]-index-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-settings-index-js": () => import("./../../../src/pages/settings/index.js" /* webpackChunkName: "component---src-pages-settings-index-js" */)
}

